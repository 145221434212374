<template>
  <div class="about">
    <img
      src="../assets/img/about/pearflot.jpg"
      class="aboutimg wow animate fadeInUp"
      alt=""
    />
    <div class="willding wow animate fadeInUp">
      <div class="ebtenre">
        {{ $t("login.shiname") }}
      </div>
      <div class="ahliimg">
        <span>{{ $t("login.hospion") }}</span>
        <img src="../assets/img/about/liqun.png" alt="" />
      </div>
    </div>
    <div class="andinnova">
      <div class="backisgray">
        <div class="fisstdiv wow animate fadeInUp delay-200ms">
          {{ $t("login.about1") }}
        </div>
        <div class="including wow animate fadeInUp delay-300ms">
          {{ $t("login.about11") }}
        </div>
        <!-- <img src="../assets/img/about/guangchang.png" class="sincefa" alt="" /> -->
        <video
          width="100%"
          controls
          autoplay="autoplay"
          class="sincefa aboutvideo wow animate fadeInUp delay-400ms"
        >
          <source src="../assets/a.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="andinnova andinnovatwo">
      <div class="backisgray">
        <div class="fisstdiv wow animate fadeInUp">
          {{ $t("login.about2") }}
        </div>
        <div class="including wow animate fadeInUp delay-100ms">
          {{ $t("login.about22") }}
        </div>
        <div class="fisstdiv wow animate fadeInUp">
          {{ $t("login.about3") }}
        </div>
        <div class="including wow animate fadeInUp delay-200ms">
          {{ $t("login.about33") }}
        </div>
        <img
          src="../assets/img/about/fatherandmother.png"
          class="sincefa wow animate fadeInUp delay-300ms"
          alt=""
        />
      </div>
    </div>
    <div class="andinnova andinnovatwo andinnovathree">
      <div class="backisgray">
        <div class="fisstdiv wow animate fadeInUp">
          {{ $t("login.about4") }}
        </div>
        <div class="including wow animate fadeInUp delay-200ms">
          {{ $t("login.about44") }}
        </div>
        <img
          src="../assets/img/about/a.png"
          class="sincefa safawo wow animate fadeInUp delay-300ms"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {};
  },
  created() {
    this.$route.meta.title = this.$t("login.querythere18");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      this.$route.meta.title = this.$t("login.querythere18");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },

  methods: {},
};
</script>
<style lang='less' scoped>
.about {
  .aboutimg {
    width: 100%;
    height: 100%;
  }
  .willding {
    position: absolute;
    top: 20%;
    left: 15%;
    .ebtenre {
      font-size: 24px;
      /* font-family: Arial; */
      font-weight: bold;
      color: black;
      line-height: 40px;
    }
    .ahliimg {
      span {
        font-size: 16px;
        /* font-family: Arial; */
        font-weight: bold;
        color: black;
      }
      img {
        vertical-align: middle;
        width: 120px;
        /* height: 85px; */
      }
    }
  }
  .andinnova {
    background-color: #f3f3f3;
    margin-top: -10px;
    .backisgray {
      padding-top: 30px;
      margin: 0 auto;
      width: 1200px;
      /* height: 790px; */
      overflow: hidden;
      .fisstdiv {
        margin-top: 40px;
        font-size: 28px;
        font-family: Arial;
        font-weight: bold;
        color: #151515;
        line-height: 48px;
        text-transform: uppercase;
      }
      .including {
        margin-top: 24px;
        font-size: 14px;
        /* font-family: Arial; */
        font-weight: 400;
        color: #151515;
        line-height: 22px;
      }
      .sincefa {
        margin-top: 33px;
        /* margin-bottom: 40px; */
      }
      .safawo {
        width: 1200px;
        height: 501px;
      }

      .aboutvideo {
        margin-bottom: 80px;
      }
    }
  }
  .andinnovatwo {
    background-color: white;
  }
  .andinnovathree {
    margin-bottom: 80px;
  }
}
</style>
